<template>
    <div>
        <Dialog v-if="approvalVisible" :create="true" @on-close="closeIDsPopup" title="证件照管理" :left="140" :top="105"
            :width="1748">
            <!--待审批 -->
            <div class="Box">
                <!-- 赛选栏 -->
                <div class="select">
                    <el-input v-model="edit_form.name" class="w170 mr10 dib " size="mini" placeholder="请输入姓名" clearable>
                    </el-input>
                    <el-select v-model="edit_form.certificateType" size="mini" clearable placeholder="请选择证件类型"
                        class="button">
                        <el-option v-for="item in drd.cates" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                    <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" class="mr10" v-model="edit_form.startTime"
                        type="datetime" placeholder="请选择开始时间">
                    </el-date-picker>
                    <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" class="mr10" v-model="edit_form.endTime"
                        type="datetime" placeholder="请选择结束时间">
                    </el-date-picker>
                    <el-select v-model="edit_form.auditState" size="mini" clearable placeholder="请选择证审批状态"
                        class="button">
                        <el-option v-for="item in drd.appList" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                    <div class="btn" @click="reset">重置</div>
                    <div class="btn" @click="searchData">查询</div>
                    <div class="btn">
                        <JsonExcel :fetch='fetch' :fields="json_fields" name="证件照统计.xls">
                            一键导出
                        </JsonExcel>

                    </div>
                    <div class="dib btn" @click="dialogVisible = true">
                        导入
                    </div>

                </div>
                <!-- 表格 -->
                <Table class="task-table" :columns="columns" :dataSource="dataSource" :pagination="pagination"
                    @size-change="
                    (size) => {
                        pagination.size = size;
                        async_get_list();
                    }" @page-change="(page) => { async_get_list(page) }">

                    <template #index="{ row }">
                        <div class="action">
                            {{ row.index }}
                        </div>
                    </template>
                    <template #ownerType="{ row }">
                        <div class="action">
                            {{ row.ownerType == 1 ? '个人 ' : '单位' }}
                        </div>
                    </template>
                    <template #auditState="{ row }">
                        <div class="action">
                            {{ auditState[row.auditState] }}
                        </div>
                    </template>
                    <template #prefecture="{ row }">
                        <div class="action">
                            {{ prefecture[row.prefecture] }}
                        </div>
                    </template>

                    <template #certificateType="{ row }">
                        <div class="action">
                            {{ certificateType[row.certificateType] }}
                        </div>
                    </template>


                    <template #action="{ row }">
                        <div class="action">
                            <div v-if="row.auditState == 1">
                                <el-tooltip content="审批" placement="top" effect="light">
                                    <div class="cp iconfont f4f7 f20 icon-baogaoshenhe" @click="approval(row)"></div>
                                </el-tooltip>
                            </div>
                            <div v-else>
                                <el-tooltip content="详情" placement="top" effect="light">
                                    <div class="cp iconfont f4f7 f20 icon-chakanxiangqing" @click="approval(row)"></div>
                                </el-tooltip>
                            </div>


                        </div>
                    </template>
                </Table>
            </div>
        </Dialog>
        <Dialog v-else :create="true" @on-close="back" :title="edit_form.type == 1 ? '无人机执照详情' : '详情'" :left="140"
            :top="105" :width="900">
            <div class="djInfoBox cf wh100">
                <div class="wh100" v-if="approvalList != null">
                    <div class="mb20 f16"><span class="dec dib mr10"></span> 无人机执照信息</div>
                    <div class="item">
                        <div>姓名<span>{{ approvalList.userName }}</span></div>
                        <div>手机号：<span>{{ approvalList.phone }}</span></div>
                        <div>证件类型：<span>{{ certificateType[approvalList.certificateType] }}</span></div>
                        <div>所属辖区：<span>{{ prefecture[approvalList.prefecture] }}</span></div>
                        <div>所属街道：<span>{{ prefecture[approvalList.street] }}</span></div>
                        <div>类别级别：<span>{{  classNameType(approvalList) }}</span></div>
                        <div>分类级别：<span>{{ approvalList.classificationName || 1 }}级</span></div>
                        <div>驾驶员等级：<span>{{ approvalList.driverName }}</span></div>
                        <div>签发日期：<span>{{ approvalList.auditTime }}</span></div>

                        <div>起始日期：<span>{{ approvalList.startTime }}</span></div>
                        <div>截止日期：<span>{{ approvalList.endTime }}</span></div>
                    </div>
                    <el-divider></el-divider>
                    <div class="mb20 f16"><span class="dec dib mr10"></span> 无人机执照照片</div>
                    <div class="item">
                        <div>照片：<span><span class="cp" style="color:#2727fd"
                                    @click="showImg(approvalList.accessory)">查看照片</span></span></div>
                    </div>
                    <el-divider></el-divider>
                    <div class="mb20 f16"><span class="dec dib mr10"></span> 审批记录</div>
                    <div class="item">
                        <div>审批时间：<span>{{ approvalList.auditTime }}</span></div>
                        <div>审批状态：<span class="cp" style='color: green;' v-if="approvalList.auditState == 2">审核通过</span>
                            <span class="cp" v-if="approvalList.auditState == 3" style='color: red;'>审核不通过</span>
                        </div>
                        <div>审批人：<span class="cp">{{ approvalList.auditUser }}</span></div>
                        <div>审批单位：<span class="cp">{{ approvalList.auditUnit }}</span></div>
                        <div></div>
                        <div></div>
                        <div class=" ">
                            <div class="dib w60"> 审批备注:
                                <el-input type="textarea " class="w800 vat" style="vertical-align: top;" disabled
                                    :rows="2" placeholder="请输入内容" v-model="approvalList.auditRemark">
                                </el-input>
                            </div>

                        </div>
                    </div>


                    <div class="mt20 tc wih00" style="margin:0 auto">
                        <div class=" btn" @click="back">返回</div>
                    </div>

                </div>
            </div>
        </Dialog>
        <el-dialog title="选择证件类型" :visible.sync="dialogVisible" width="30%" :append-to-body="true"
            :before-close="handleClose">
            <div class="jcsb cf w260">
                证件类型： <el-select v-model="xlsType" size="mini" clearable placeholder="请选择导入证件类型" class="button">
                    <el-option v-for="item in drd.cates" :key="item.id" :label="item.title" :value="item.id" />
                </el-select>
            </div>

            <span slot="footer" class="dialog-footer">
                <div class="btn" @click="dialogVisible = false">取 消</div>
                <el-upload method="post" :auto-upload="true" :headers="headers" :show-file-list="false"
                    accept=".xlsx, .xls" :multiple="false" :http-request="(param) => airUpload(param)"
                    :on-success="onUploadSuccess" action="#" class="upload-demo dib">
                    <div class="btn dib">导入</div>
                </el-upload>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Dialog from "@/pages/civilAircraft/components/dialog"
import Table from "@/components/common/table-new-hwb"; // 列表组件
import API from "@/api";
import JsonExcel from 'vue-json-excel'
import ImgDialog from "@/pages/civilAircraft/components/imgDialog"
import VueViewer from "v-viewer";
import Vue from "vue";
import "viewerjs/dist/viewer.css";
Vue.use(VueViewer);
export default {
    components: { Dialog, Table, JsonExcel, ImgDialog },

    data() {
        return {
            dialogVisible: false,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            xlsType: null,
            approvalVisible: true,
            ImgDialogFlag: false,
            textarea: '',
            approvalVisible: true,
            dataSource: [
            ],
            dataSource2: [],
            // 证件类型 （1:无人机执照 2:utc合格证 3:asfc飞行员执照）
            certificateType: {
                1: '无人机执照',
                2: 'utc合格证',
                3: "asfc飞行员执照"
            },
            // 1:待审核 2:审核通过 3:审核不通过
            auditState: {
                1: '待审核',
                2: '审核通过',
                3: "审核不通过"
            },
            json_fields: {
                "序号": "index",    //常规字段
                "姓名": "userName",
                "证件类型": "documentsType",
                "手机号": "phone",
                "身份证号": "documentsNumber",
                "所属辖区": "prefecture",
                "所属街道": "street",
                "类别级别": "classesName",
                "分类级别": "classificationName",
                "驾驶员等级": "driverName",
                "签发日期": "issuingTime",
                "起始日期": "startTime",
                "截止日期": "endTime",
                "无人机执照照片": "policy",
                "审批时间": "auditTime",
                "审批状态": "auditState",
                "审批人": "auditUser",
                "审批单位": "auditUnit",
                "审批备注": "auditRemark",
            },
            columns: [
                { prop: "index", label: "序号", slot: true, width: "100px" },
                { prop: "auditTime", label: "审批时间", width: "230px" },
                { prop: "userName", label: "姓名", width: "170px" },
                { prop: "certificateType", slot: true, label: "证件类型", width: "170px" },
                { prop: "phone", label: "手机号", width: "170px" },
                { prop: "documentsNumber", label: "身份证号", width: "170px" },
                { prop: "prefecture", slot: true, label: "所属辖区", width: "170px" },
                { prop: "startTime", label: "起始日期", width: "170px" },
                { prop: "endTime", label: "截止日期", width: "170px" },
                { prop: "auditState", slot: true, label: "审批状态", width: "170px" },

                { prop: "action", slot: true, label: "操作", width: "170px" },
            ],
            pagination: {
                page: 1,
                pageSize: 10,
                total: 0,
            },
            edit_form: {  // 发起需求表单字段
                certificateType: null,
                type: 2,
                endTime: null,
                name: null,
                startTime: null,
                page: "1",
                size: "10",
                purpose: null,
                auditState:null,//审批状态
            },
            selectList: null, // 赛选的机构
            drd: {
                cates: [
                    { id: 1, title: "无人机执照" },
                    { id: 2, title: "utc合格证" },
                    { id: 3, title: "asfc飞行员执照" },

                ],
                scenes: null,
                uav_type: [],
                mount_type: [],
                task_type: [],
                task_cate: [],
                flightLine: [],
                certificateType: [
                    { id: 1, label: "待处理", value: 0 },
                    { id: 2, label: "处理完成", value: 1 },
                    { id: 3, label: "无需处理", value: 2 },
                ],
                appList:[
                    {id:1,title:'待审核'},
                    {id:2,title:'审核通过'},
                    {id:3,title:'审核不通过'},
                ]
            },
            prefecture: {
                1: '许村镇',
                2: '长安镇',
                3: '周王庙镇',
                4: '盐官镇',
                5: '丁桥镇',
                6: '斜桥镇',
                7: '袁花镇',
                8: '黄湾镇',
                9: '硖石街道',
                10: '海洲街道',
                11: '海昌街道',
                12: '马桥街道',
            },

            approvalList: null

        }
    },
    created() {
        this.async_get_list()
    },
    methods: {
        classNameType(approvalList){
            if (approvalList.certificateType== 1) {
              return approvalList.classesName 
            } else {
              return approvalList.categoryName
            }
        },
        handleClose() {
            this.dialogVisible = false

        },
      
        async airUpload(file) {
            if (!this.xlsType) return this.$message.warning('请选择导入证件类型！')
            let res = null;
            let formData = new FormData();
            formData.append("file", file.file);
            try {
                res = await API.AIRCRAFT.certificateInfImport({
                    file: formData,
                    type: this.xlsType
                });
                if (res.code === 200) {
                    this.$message.success("数据添加成功!");
                    this.pagination.page = 1
                    this.async_get_list()
                } else {
                    this.$message.error("数据添加失败,请按照模板上传文件!");
                }
            } catch (error) {
                console.log(error);
            }
        },
        onUploadSuccess(response, file, fileList) {
            console.log(response, file, fileList, 'response, file, fileList');
        },
        fetch() {
            this.dataSource2.forEach((v, index) => {
                v.index = index + 1
                v.prefecture = this.prefecture[v.prefecture]
                v.street = this.prefecture[v.street]
                v.auditState = this.auditState[v.auditState]

            })
            return this.dataSource2
        },
        showImg(src) {
            // if (src) {
            //     this.src = src
            //     this.ImgDialogFlag = true
            // } else {
            //     this.$message.warning('暂无照片！')
            // }
            let imgList = JSON.parse(src).map(v=>v.url)
            if (imgList.length) {
                this.$viewerApi({
                    images:imgList
                })
            } else {
                this.$message.warning('暂无照片！')
            }
        },
        back() {
            this.approvalList = null
            this.textarea = null;
            this.approvalVisible = true
            this.$forceUpdate()
        },
        async approvalHandClick(num) {
            if (this.textarea == '') return this.$message.warning('请输入备注信息！')
            let res = await API.AIRCRAFT.certificateInfoApproval({
                id: this.approvalList?.id,
                auditState: num,
                auditRemark: this.textarea
            })
            if (res.code == 200) {
                this.back()
                this.$message.success('操作成功')
                this.pagination.page = 1
                this.async_get_list()
            } if (res.code == 201) {
                return this.$message.warning('已审批，无需审批')
            }
        },
        // 审批
        approval(approval) {
            console.log(approval);
            if (approval) {
                let data = this.dataSource.filter(item => item.id == approval.id)
                this.approvalList = JSON.parse(JSON.stringify(data[0]))
            }
            this.approvalVisible = false
        },
        reset() {
            this.edit_form = {  // 发起需求表单字段
                certificateType: null,
                type: 2,
                endTime: null,
                name: null,
                startTime: null,
                page: "1",
                size: "10",
                purpose: null
            }
            this.async_get_list()

        },
        searchData() { // 搜索按钮
            this.pagination.page = 1
            this.async_get_list()
        },
        closeIDsPopup() {
            this.$emit('closeDemand');
        },
        async async_get_list(page) {
            if (page) {
                this.pagination.page = page
            }
            for (const key in this.pagination) {
                if (this.edit_form[key]) {
                    this.edit_form[key] = this.pagination[key]
                }
            }
            let res = await API.AIRCRAFT.certificateInfoLists(this.edit_form)
            if (res.code == 200) {
                this.dataSource = res.data.map((item, index) => {
                    return ({
                        ...item,
                        index: index + 1
                    })
                })
                this.pagination.total = res.total
            } else {
                this.dataSource = []
                this.pagination.total = res.total

            }
            //  导出
            let form = JSON.parse(JSON.stringify(this.edit_form))
            form.size = 9999
            let DC = await API.AIRCRAFT.certificateInfoLists(form)
            if (DC.code == 200) {
                this.dataSource2 = DC.data
            }

        }
    },
}
</script>
<style lang="scss" scoped>
.f4f7 {
    color: #77f4f7
}

.Box {
    min-height: 50vh;

    .tableSelect {
        display: flex;
        justify-content: flex-start;

        .item {
            cursor: pointer;
            display: flex;
            text-align: center;
            justify-content: space-evenly;
            align-items: center;
        }

        .dsp {
            background-image: url(~@/assets/images/civilAircraft/1.png);

            &.selected {
                background-image: url(~@/assets/img/click-3.png);
            }
        }

        .sptg {
            background-image: url(~@/assets/images/civilAircraft/2.png);

            &.selected {
                background-image: url(~@/assets/img/click-1.png);
            }
        }
    }

    .select {
        margin: 0px 0 24px 0;

        ::v-deep {
            .el-input__inner {
                background-color: #000;
                border: 0;
                height: 30px;
                line-height: 30px;
                color: #fff;
                font-size: 14px;
            }
        }

        .button {
            margin-right: 10px;
            width: 210px;
        }

        .search-button {
            display: inline-block;
            width: 54px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
            border-radius: 4px;
            background-color: #000;
            color: #fff;
            text-align: center;
            box-sizing: border-box;
        }


    }

    .task-table {
        min-height: 50vh;
    }
}

.btn {
    padding: 5px 10px;
    display: inline-block;
    background: rgba(38, 71, 238, 0.71);
    box-shadow: inset 0px 1px 3px 0px #FFFFFF, inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
    border-radius: 2px;
    border: 1px solid;
    // border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    margin-right: 10px;
    cursor: pointer;
}

.btn:hover {
    background: rgba(38, 71, 238, 0.91);

}

.djInfoBox {
    display: flex;

    .dec {
        width: 4px;
        height: 17px;
        background: #FFC300;
        border-radius: 2px;
    }

    .item {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;

        div {
            min-width: 30%;
            margin-bottom: 20px;
            flex: 1;
            color: #8799b7;
            white-space: nowrap;

            span {
                display: inline-block;
                overflow: hidden;
                vertical-align: bottom;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 200px;
                margin-left: 20px;
                color: #effaff;
            }
        }
    }

}

::v-deep {
    .el-divider--vertical {
        height: 100%;
    }

    .el-textarea__inner {
        display: inline-block;
        box-sizing: border-box;
        background: rgba(12, 34, 67, 0.5) !important;
        background: transparent;
        border: 1px solid #009aff;
        border-radius: 0;
        font-size: 16px;
        color: #9fc4ff;
        letter-spacing: 0;
        font-weight: 400;
    }

    .el-dialog {
        margin-top: 5vh;
    }

}

::v-deep .el-input__icon {
    line-height: 30px;
}

::v-deep .el-input.is-disabled .el-input__inner {
    background: rgba(12, 34, 67, 0.5) !important;

}
::v-deep .el-divider {
    background-color: #6d6b9654;
}
</style>